import axios, { AxiosResponse, AxiosError } from 'axios';
import * as pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5069';

interface ApiResponse<T> {
  data: T;
  status: number;
}

interface ErrorResponse {
  message: string;
}

interface PdfResponse {
  blob: Blob;
  width: number;
  height: number;
}


export const getUserInfo = async (accessToken:string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/user_info`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

const handleApiError = (error: AxiosError): never => {
  if (error.response) {
    const errorMessage = (error.response.data as ErrorResponse)?.message || 'Unknown error occurred';
    throw new Error(`API error: ${error.response.status} - ${errorMessage}`);
  } else if (error.request) {
    throw new Error('No response received from the server');
  } else {
    throw new Error(`Error setting up the request: ${error.message}`);
  }
};

export const getMakers = async (accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_makers`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getGuidelines = async (maker: string, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_guidelines/${maker}`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getGuidelineContent = async (guidelineId: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_guideline_content/${guidelineId}`);
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const registerUser = async (userData: any): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.post(`${API_URL}/register_user`, userData);
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const updateRelevance = async (criteriaId: string, isRelevant: boolean, relevanceCategory?: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.post(`${API_URL}/update_relevance`, { criteria_id: criteriaId, is_relevant: isRelevant, relevance_category: relevanceCategory });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const updateReviewerProcessStartTime = async (caseId: string, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/update_reviewer_process_start_time/${caseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getReviewerProcessStartTime = async (caseId: string, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_reviewer_process_start_time/${caseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getCaseCreatedTime = async (caseId: string, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_case_created_time/${caseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const healthcheck = async (): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/healthcheck`);
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getMedicalRecord = async (caseId: string, filename: string, accessToken: string): Promise<ApiResponse<Blob>> => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(`${API_URL}/get_medical_record/${caseId}/${filename}`, { responseType: 'blob', headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getHistoricalViews = async (accessToken:string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_historical_views`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const pullCase = async (caseId: string, accessToken:string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.post(`${API_URL}/pull_case/${caseId}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getQueueData = async (accessToken:string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_queue_data`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getCompletedData = async (accessToken:string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_completed_data`, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};



export const processDemo = async (guidelinesId: string, json_data: any, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.post(`${API_URL}/process/${guidelinesId}`, json_data, {
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      timeout: 120000
    });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};
export const processWithExistingGuidelines = async (guidelinesId: string, medicalRecordFile: File, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const formData = new FormData();
    formData.append('medical_record', medicalRecordFile);

    const response: AxiosResponse = await axios.post(`${API_URL}/process/${guidelinesId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` },
      timeout: 180000
    });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const updateClinicalRationale = async (caseId: string, rationaleText: string, accessToken: string  ): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.post(`${API_URL}/reviewer_update_clinical_rationale`, { rationaleText: rationaleText, case_id: caseId }, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const submitCase = async (request_body: any, accessToken: string): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.post(`${API_URL}/submit_case`, request_body, { headers: { Authorization: `Bearer ${accessToken}` } });
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};

export const getSampleJson = async (sampleIndex: number): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios.get(`${API_URL}/get_sample_json/${sampleIndex}`);
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};



export const getSamplePdf = async (sampleIndex: number): Promise<ApiResponse<PdfResponse>> => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(`${API_URL}/get_sample_pdf/${sampleIndex}`, { responseType: 'blob' });
    
    // Convert blob to ArrayBuffer
    const arrayBuffer = await response.data.arrayBuffer();
    
    // Load the PDF document
    const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
    const pdf = await loadingTask.promise;    // const pdf = await loadingTask.promise;
    
    // Get the first page
    const page = await pdf.getPage(2);
    
    // Get the dimensions of the first page
    const viewport = page.getViewport({ scale: 1.0 });

    return {
      data: {
        blob: response.data,
        width: viewport.width,
        height: viewport.height
      },
      status: response.status
    };
  } catch (error) {
    return handleApiError(error as AxiosError);
  }
};