import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Box,
  Button,
  Paper,
  Divider,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  ListItemIcon,
  Slider,
  CircularProgress,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHistory } from 'react-router-dom';
import { getMedicalRecord, updateReviewerProcessStartTime, pullCase, submitCase, getQueueData } from '../libs/backend';
import {
  HighlightOff,
} from '@mui/icons-material';
import { darken } from '@mui/system';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';


import CriteriaTree from '../components/criteriaTree';
import { RationaleViewModal, RationaleEditModal } from '../components/outcomeComponents';
import { AuthContext } from '../contexts/authContext';
import { updateClinicalRationale } from '../libs/backend';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { QueueCase } from '@/routes/caseTracking';
import { SubmitConfirmationAlertDialog } from '@/components/dialogs/caseSubmissionDialog';
import { SummaryComponent } from '@/components/caseSummaryComponent';
import { overviewData, AnkleCTScan, totalAnkleReplacement, transformDemoData } from '@/data/summaryResultData';
import CaseFinalOutcome from '@/components/caseFinalOutcome';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();



export interface ClinicalDecisioning {
  bounding_box: {
    x: number;
    y: number;
    width: number;
    height: number;
    page: number;
  };
  id: string;
  relevant?: string;
  text: string;
  ocr_id: string; // TODO: This is not used, not part of the API response. Need to remove.
}

export interface Criterion {
  color: string;
  criteria_id: string;
  description: string;
  operator?: 'AND' | 'OR';
  criteria?: Criterion[];
  clinical_decisioning?: ClinicalDecisioning[];
}

interface PdfData {
  blob: Blob;
  width: number;
  height: number;
}

export interface ResultProps {
  json_data: {
    case_id: string;
    suggested_decision: string;
    suggested_rationale: string;
    criteria: Criterion;
    mcg_guideline_name: string;
    medical_record_id: string;
    number_of_pages_health_record: number;
    reason: string;
    guidelines_id: string;
    matches_count: number;
    processing_time: number;
  };
pdf_data: PdfData;
}

export const isCriterionSatisfied = (criterion: Criterion): boolean => {
  if (criterion.clinical_decisioning && criterion.clinical_decisioning.length > 0) {
    return criterion.clinical_decisioning.some(match => match.relevant === 'yes');
  }

  if (!criterion.criteria || criterion.criteria.length === 0) {
    return false;
  }

  if (criterion.operator === 'AND') {
    return criterion.criteria.every(isCriterionSatisfied);
  } else { // 'OR' or undefined
    return criterion.criteria.some(isCriterionSatisfied);
  }
};

export const CriterionComponent: React.FC<{ 
  criterion: Criterion; 
  onMatchClick: (match: ClinicalDecisioning) => void;
}> = ({ criterion, onMatchClick }) => {
  const hasSubCriteria = criterion.criteria && criterion.criteria.length > 0;
  const hasMatches = criterion.clinical_decisioning && criterion.clinical_decisioning.length > 0;
  const isSatisfied = isCriterionSatisfied(criterion);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center">
          {isSatisfied ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <CancelOutlinedIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography>
            {criterion.criteria_id} - {criterion.description}
            {criterion.operator && ` (${criterion.operator})`}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ pl: 2 }}>
          {hasSubCriteria && criterion.criteria!.map((subCriterion) => (
            <CriterionComponent 
              key={subCriterion.criteria_id} 
              criterion={subCriterion} 
              onMatchClick={onMatchClick}
            />
          ))}
          {hasMatches && (
            <Box>
              <Typography variant="subtitle1">Matches:</Typography>
              {criterion.clinical_decisioning!.map((match, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2">
                    • {match.text} (Relevant: {match.relevant})
                  </Typography>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={() => onMatchClick(match)}
                  >
                    Show in PDF
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};



const checkRationale = (reviewer_rationale: string, json_data: any, coming_from: string, isHistoricalView: boolean) => {
  if (coming_from === "historical_views" && isHistoricalView) {
    return [reviewer_rationale, 'Reviewer'];
  }
  return [json_data.suggested_rationale, 'Suggested'];
}

const checkDecision = (reviewer_decision: string, json_data: any, coming_from: string, isHistoricalView: boolean) => {
  if (coming_from === "historical_views" && isHistoricalView) {
    return [reviewer_decision, 'Reviewer'];
  }
  return [json_data.suggested_decision, 'Suggested'];
}

const setTimer = (reviewer_process_start_time: number, coming_from: string, reviewer_decision: string, setElapsedTime: React.Dispatch<React.SetStateAction<number>>) => {
  if (coming_from == "case_tracking") {
    const startTime = reviewer_process_start_time * 1000;

      const timer = setInterval(() => {
        const now = Date.now();
        setElapsedTime(Math.floor((now - startTime) / 1000));
    }, 1000);
    return () => clearInterval(timer);
  } else if (coming_from === "demo") {
    setElapsedTime(0);
    const timer = setInterval(() => {
      setElapsedTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }

  else if (reviewer_decision === null) {
    setElapsedTime(Math.floor(reviewer_process_start_time));
    const timer = setInterval(() => {
      setElapsedTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }
  else if (coming_from == "historical_views") {
    setElapsedTime(Math.floor(reviewer_process_start_time));
  }
  else {
    setElapsedTime(Math.floor(reviewer_process_start_time));
    const timer = setInterval(() => {
      setElapsedTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }
  
}

const getColorHex = (color: string): string => {
  switch (color.toLowerCase()) {
    case 'green':
      return '#4caf50';
    case 'red':
      return '#f44336';
    case 'yellow':
      return '#ffeb3b';
    case 'grey':
      return '#9e9e9e';
    default:
      return '#000000';
  }
};
const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

const mapDecisionToDropdownValue = (decision: string): string => {
  const mapping: { [key: string]: string } = {
    approved: 'Approved',
    denied: 'Denied',
    inconclusive: 'Inconclusive',
    'no info found': 'No Info Found',
  };
  const normalizedDecision = decision.toLowerCase();
  return mapping[normalizedDecision] || decision;
};

const getDecisionIcon = (status: string) => {
  switch (status) {
    case 'Approved':
      return <CheckCircleOutlineIcon color="success" />;
    case 'Denied':
      return <CancelOutlinedIcon color="error" />;
    case 'No Info Found':
      return <HelpOutlineIcon style={{ color: "black" }} />;
    case 'Inconclusive': 
      return <InfoOutlinedIcon style={{ color: "black" }} />; 
    default:
      return <HelpOutlineIcon color="disabled" />;
  }
};

const getDecisionColor = (status: string): { color: 'default' | 'success' | 'error' | 'warning' | 'primary' | 'secondary' | 'info'; backgroundColor?: string } => {
  switch (status) {
    case 'Approved':
      return { color: 'success' };
    case 'Denied':
      return { color: 'error' };
    case 'No Info Found':
      return { color: 'default', backgroundColor: 'grey' };
    case 'Inconclusive': 
      return { color: 'default', backgroundColor: 'yellow' };
    default:
      return { color: 'warning' };
  }
};

const getSelectDecisionColor = (status: string) => {
  switch (status) {
    case 'Approved':
      return '#388e3c' ;
    case 'Denied':
      return '#d32f2f';
    case 'No Info Found':
      return 'grey';
    case 'Inconclusive':
      return 'yellow';
    default:
      return 'transparent';
  } 
}

// ! Summary Data
const getSummaryData = (mcg_guideline_name: string, reviewer_data: any, demo_data: any) => {

  if (reviewer_data?.email.toLowerCase().includes('hermine')) {
    const demoData = transformDemoData(demo_data)
    return demoData
  }

  else if (mcg_guideline_name === "A-0014 - Ankle CT Scan") { 
    return AnkleCTScan
  } 
  
  else if (mcg_guideline_name === "Total_Ankle_Replacement") {
    // console.log("reviewer_data", reviewer_data)
    return totalAnkleReplacement
  } 
  
  else {
    return overviewData
  }
}


export const Result: React.FC<ResultProps> = () => {
  // handles value that is coming from the previous page
  const history = useHistory();
  const location = useLocation<{json_data: any, pdf_data: PdfData, reviewer_process_start_time: any, coming_from: string, reviewer_data: any, reviewer_decision: string, reviewer_rationale: string, demo_data: any}>()
  const json_data = location.state.json_data
  const pdf_data = location.state.pdf_data
  const reviewer_data = location.state.reviewer_data
  const demo_data = location.state.demo_data
  
  var reviewer_process_start_time = location.state.reviewer_process_start_time
  const coming_from = location.state.coming_from

  // handles the submit case dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [queueData, setQueueData] = useState<QueueCase[]>([]);
  const [isNextCase, setIsNextCase] = useState<boolean>(false);
  const [isLoadingNextCase, setIsLoadingNextCase] = useState(false);

  // handles value that is coming from historical views 
  const reviewer_decision = location.state.reviewer_decision
  const reviewer_rationale = location.state.reviewer_rationale
  const isHistoricalView = coming_from === 'historical_views' && reviewer_decision !== null; 
  const isDemo = coming_from === 'demo'

  // setting the decision and rationale values
  const [decisionValue, decisionLabel] = checkDecision(reviewer_decision, json_data, coming_from, isHistoricalView)
  const [rationaleValue, rationaleLabel] = checkRationale(reviewer_rationale, json_data, coming_from, isHistoricalView)
  const mappedDecisionValue = mapDecisionToDropdownValue(decisionValue || '');

  // handles the timer
  const [elapsedTime, setElapsedTime] = useState(0);

  // pdf viewer
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [currentBoundingBox, setCurrentBoundingBox] = useState<ClinicalDecisioning['bounding_box'] | null>(null);
  const [viewerWidth, setViewerWidth] = useState<number>(0);
  const [currentBoundingBoxColor, setCurrentBoundingBoxColor] = useState<string>('#ff5722');
  const [currentCriteriaId, setCurrentCriteriaId] = useState<string | null>(null);
  const [zoom, setZoom] = useState(1);
  const [showZoomLevel, setShowZoomLevel] = useState(false);
  const [originalBoundingBox, setOriginalBoundingBox] = useState<ClinicalDecisioning['bounding_box'] | null>(null);
  const ZOOM_STEP = 0.05;
  const MAX_ZOOM = 3;
  const MIN_ZOOM = 0.75;


  // handles the decision dropdown
  const [dropdownValue, setDropdownValue] = useState<string>('');
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  
  // handles the error
  const [error, setError] = useState<string | null>(null);
  const auth = useContext(AuthContext);
  
  // handles the rationale text
  const [originalRationaleText, setOriginalRationaleText] = useState<string>(rationaleValue);
  const [currentRationaleText, setCurrentRationaleText] = useState<string>(rationaleValue);

  // handles the submission of the case
  const [isSubmitCase, setIsSubmitCase] = useState<boolean>(false);
  
  // ! responsiveness 
  // handles the scale of the pdf
  const [scale, setScale] = useState(1);
  const [pdfContainerWidth, setPdfContainerWidth] = useState(0);
  const [pdfContainerHeight, setPdfContainerHeight] = useState(0);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  // handles the left panel width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [leftPanelWidth, setLeftPanelWidth] = useState<number>(60);

  // handles the divider
  const dividerRef = useRef<HTMLDivElement>(null);

  // console.log("json_data", json_data)
  // handles the continue to cases dialog
  const handleContinueToCases = async () => {
    setIsDialogOpen(false);

    setIsSubmitCase(true);
    setError(null);
    try {
      
      const request_body = {
        case_id: json_data.case_id,
        reviewer_decision: dropdownValue,
        reviewer_rationale: currentRationaleText,
        reviewer_process_end_time: formatTime(elapsedTime), 
      }
      const response = await submitCase(request_body, auth?.sessionInfo?.accessToken || '');
      history.push('/caseTracking', { new_submission: true });
    } catch (error) {
      console.error('Error processing:', error);
      setError('Failed to process the file. Please try again.');
    } finally {
      setIsSubmitCase(false);
    }
  }

  // handles the continue to next case dialog
  const handleContinueToNextCase = async () => {
    if (!auth?.sessionInfo?.accessToken) return;
    setIsLoadingNextCase(true);
    setIsSubmitCase(true);
    setError(null);
    setIsDialogOpen(false)
    
    try {
      const request_body = {
        case_id: json_data.case_id,
        reviewer_decision: dropdownValue,
        reviewer_rationale: currentRationaleText,
        reviewer_process_end_time: formatTime(elapsedTime), 
      }
      const response = await submitCase(request_body, auth?.sessionInfo?.accessToken || '');
    } catch (error) {
      console.error('Error processing:', error);
      setError('Failed to process the file. Please try again.');
      setIsLoadingNextCase(false);
      return;
    } finally {
      setIsSubmitCase(false);
      setIsLoadingNextCase(false);
    }

    try {
        const pulledCase = await pullCase(queueData[0].case_id, auth.sessionInfo.accessToken);
        
        const caseResponse = pulledCase.data.result.case;
        const patientResponse = pulledCase.data.result.patient;
        const s3PathParts = caseResponse.clinical_attachment_s3_path.split('/');
        const fileName = s3PathParts[s3PathParts.length - 1];
        
        const pdfResponse = await getMedicalRecord(caseResponse.case_id, fileName, auth?.sessionInfo?.accessToken || '');

        const reviewerTimerResponse = await updateReviewerProcessStartTime(caseResponse.case_id, auth?.sessionInfo?.accessToken || '');
        history.push('/results', {
            json_data: caseResponse.rational_decisioning_criterias,
            pdf_data: {
                blob: pdfResponse.data,
                width: caseResponse.rational_decisioning_criterias.width,
                height: caseResponse.rational_decisioning_criterias.height,
            },
            reviewer_process_start_time: reviewerTimerResponse.data.result,
            coming_from: "case_tracking",
        })
    } catch (error) {
        console.error('Error going to next case:', error)
        setError('Failed to pull case and navigate to next case. Please try again later.');
    }
  }

  // handles the fetch queue data
  const fetchQueueData = async () => {
    if (auth?.sessionInfo?.accessToken) {
        try {
            const response = await getQueueData(auth.sessionInfo.accessToken);
            setError(null);
            return response.data;
        } catch (error) {
            console.error('Error fetching queue data:', error);
            setError('Failed to fetch queue data. Please try again later.');
        } 
    }
  };

  // handles the mouse down, move, and up
  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, []);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (dividerRef.current) {
      const newLeftWidth = (e.clientX / window.innerWidth) * 100;
      setLeftPanelWidth(Math.min(Math.max(newLeftWidth, 25), 80));
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  // removes the mouse move and up event listener
  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);
  
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setLeftPanelWidth(newValue as number);
  };
  // ! This has been modified
  // const updateViewerWidth = useCallback(() => {
  //   const maxWidth = window.innerWidth / 3;
  //   const newWidth = Math.min(pdf_data.width, maxWidth);
  //   setViewerWidth(newWidth*.85);
  // }, [pdf_data.width]);

  // useEffect(() => {
  //   updateViewerWidth();
  //   window.addEventListener('resize', updateViewerWidth);
  //   return () => window.removeEventListener('resize', updateViewerWidth);
  // }, [updateViewerWidth]);

  // updates the scale of the pdf
  const updateScale = useCallback(() => {
    if (pdfContainerRef.current && numPages) {
      const containerWidth = pdfContainerRef.current.clientWidth;
      const containerHeight = pdfContainerRef.current.clientHeight;
      setPdfContainerWidth(containerWidth);
      setPdfContainerHeight(containerHeight);

      const pageAspectRatio = pdf_data.height / pdf_data.width;
      
      const widthScale = containerWidth / pdf_data.width;
      const heightScale = containerHeight / (pdf_data.height);
      
      const newScale = Math.min(widthScale, heightScale);
      setScale(newScale);
      setViewerWidth(pdf_data.width);
    }
  }, [numPages, pdf_data.width, pdf_data.height]);

  useEffect(() => {
    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, [updateScale, leftPanelWidth]);

  // updates the viewer width
  const updateViewerWidth = useCallback(() => {
    const maxWidth = windowWidth < 1200 ? windowWidth * 0.9 : (100 - leftPanelWidth) / 100 * windowWidth;
    const newWidth = Math.min(pdf_data.width, maxWidth);
    setViewerWidth(newWidth * 0.95);
  }, [pdf_data.width, windowWidth, leftPanelWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // updates the viewer width when the window size or left panel width changes
  useEffect(() => {
    updateViewerWidth();
  }, [updateViewerWidth, windowWidth, leftPanelWidth]);

  // ! end of responsiveness

  // updates the number of pages
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  // PDF Viewer Zoom
  const handleZoomIn = () => {
    setZoom(prevZoom => Math.min(prevZoom + ZOOM_STEP, MAX_ZOOM));
    setShowZoomLevel(true);
    setTimeout(() => setShowZoomLevel(false), 2000);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => Math.max(prevZoom - ZOOM_STEP, MIN_ZOOM));
    setShowZoomLevel(true);
    setTimeout(() => setShowZoomLevel(false), 2000);
  };

  // * only keep track of the original bounding box without scaling
  // * afterwards, calculate the scaled bounding box
  const handleMatchClick = useCallback((match: ClinicalDecisioning, color: string, criterionId: string) => {
    if (match && match.bounding_box) {

      setOriginalBoundingBox(match.bounding_box);
      setCurrentBoundingBoxColor(color);
      setCurrentCriteriaId(criterionId);
      setPageNumber(match.bounding_box.page);
      
      const scale = viewerWidth / pdf_data.width;
      const scaledBox = calculateScaledBoundingBox(match.bounding_box, scale, zoom, json_data.suggested_decision);
      setCurrentBoundingBox(scaledBox);
    }
  }, [viewerWidth, pdf_data.width, json_data.suggested_decision, zoom]);

  // * making scaling calculations for the bounding box
  const calculateScaledBoundingBox = (
    originalBox: ClinicalDecisioning['bounding_box'],
    scale: number,
    currentZoom: number,
    suggestedDecision: boolean
  ) => {
    const baseMultiplier = suggestedDecision ? 1 : 612.0;
    const heightMultiplier = suggestedDecision ? 1 : 792.0;
  
    return {
      x: originalBox.x * scale * 0.995 * baseMultiplier * currentZoom,
      y: originalBox.y * scale * 0.997 * heightMultiplier * currentZoom,
      width: originalBox.width * scale * 1.01 * baseMultiplier * currentZoom,
      height: originalBox.height * scale * 1.34 * heightMultiplier * currentZoom,
      page: originalBox.page,
    };
  };

  // * scaling on zoom changes effect
  useEffect(() => {
    if (originalBoundingBox) {
      const scale = viewerWidth / pdf_data.width;
      const updatedBox = calculateScaledBoundingBox(
        originalBoundingBox,
        scale,
        zoom,
        json_data.suggested_decision
      );
      setCurrentBoundingBox(updatedBox);
    }
  }, [zoom, viewerWidth, pdf_data.width, json_data.suggested_decision, originalBoundingBox]);

  // * scaling on window changes effect
  useEffect(() => {
    if (originalBoundingBox) {
      const scale = viewerWidth / pdf_data.width;
      const updatedBox = calculateScaledBoundingBox(
        originalBoundingBox,
        scale,
        zoom,
        json_data.suggested_decision
      );
      setCurrentBoundingBox(updatedBox);
    }
  }, [viewerWidth]);

    // ! first match
    const [highlightedCriterionId, setHighlightedCriterionId] = useState<string | null>(null);

    const findFirstMatch = useCallback((criterion: Criterion): Criterion | null => {
      // Check if the criterion has matches
      if (criterion.clinical_decisioning && 
          criterion.clinical_decisioning.length > 0 && 
          criterion.clinical_decisioning[0].text !== 'None') {
        return criterion;
      }
      
      // If this criterion doesn't have a match, check its children
      if (criterion.criteria) {
        for (const subCriterion of criterion.criteria) {
          const match = findFirstMatch(subCriterion);
          if (match) return match;
        }
      }
    
      return null;
    }, []);
    

    useEffect(() => {
      const firstMatchCriterion = findFirstMatch(json_data.criteria);
      if (firstMatchCriterion) {
        setHighlightedCriterionId(firstMatchCriterion.criteria_id);
        
        setTimeout(() => {
          const element = document.querySelector(`[data-highlighted="${firstMatchCriterion.criteria_id}"]`);
          if (element) {
            let parent = element.closest('.MuiAccordion-root');
            while (parent) {
              if (!parent.classList.contains('Mui-expanded')) {
                const summary = parent.querySelector('.MuiAccordionSummary-root');
                if (summary) {
                  (summary as HTMLElement).click();
                }
              }
              const nextParent = parent.parentElement;
              if (nextParent) {
                parent = nextParent.closest('.MuiAccordion-root');
              } else {
                break;
              }
            }
    
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 300);
          }
        }, 100);
    
        // Remove the highlight after 5 seconds
        const timer = setTimeout(() => {
          setHighlightedCriterionId(null);
        }, 5000);
    
        return () => clearTimeout(timer);
      }
    }, [json_data.criteria, findFirstMatch]);
    // ! end of first match

  // handles the previous page
  const goPrevPage = () => {
    setPageNumber(prev => {
      const newPage = prev > 1 ? prev - 1 : 1;
      return newPage;
    });
  };

  // handles the next page
  const goNextPage = () => {
    setPageNumber(prev => {
      const newPage = prev < (numPages || 1) ? prev + 1 : (numPages || 1);
      return newPage;
    });
  };

  // handles the decision dropdown change
  const handleDropdownChange = (value: string) => {
    setDropdownValue(value);
  };

  // handles the view modal
  const handleOpenViewModal = () => setIsViewModalOpen(true);
  const handleCloseViewModal = () => setIsViewModalOpen(false);

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  // handles the submission of the case which leads to the submit case dialog
  const handleSubmitCase = async () => {
    setIsDialogOpen(true);
    const response = await fetchQueueData();
    if (!response[0]) {
      console.log("no case found")
      setIsNextCase(true)
    }
    setQueueData(response)
  };
  
  // decision dropdown use effect
  useEffect(() => {
    setDropdownValue(mappedDecisionValue);
  }, [mappedDecisionValue]);

  // timer use effect
  useEffect(() => {
    const timer = setTimer(reviewer_process_start_time, coming_from, reviewer_decision, setElapsedTime)
    return timer;
  }, [reviewer_process_start_time]);

  // rationale edit modal use effect
  const handleSubmitEditedRationale = useCallback(async (editedText: string) => {
    setError(null);

    try {
      const response = await updateClinicalRationale("123", editedText, auth?.sessionInfo?.accessToken || '');      
      
      if (response.status !== 200) {
        setError('Failed to update rationale. Please try again.');
        return;
      }
      
      setCurrentRationaleText(editedText);
      setOriginalRationaleText(editedText);
      
    } catch (error) {
      console.error('Error updating rationale:', error);
      setError('Failed to update rationale. Please try again.');
    }
  }, [auth?.sessionInfo?.accessToken]);

  useEffect(() => {
    // console.log("originalRationaleText", originalRationaleText);
    // console.log("currentRationaleText", currentRationaleText);
  }, [currentRationaleText, originalRationaleText]);

  const decisionStyle = getDecisionColor(mappedDecisionValue);

  // ! Summary Data
  let summaryData = getSummaryData(json_data.mcg_guideline_name, reviewer_data, demo_data);
  

  return (
    <>
    {isLoadingNextCase && (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
          <CircularProgress className="h-12 w-12 mb-4" />
          <Typography variant="h6" className="text-gray-700 dark:text-gray-200">
            Loading next case...
          </Typography>
          <Typography variant="body2" className="text-gray-500 dark:text-gray-400 mt-2">
            Please wait while we prepare your next case
          </Typography>
        </div>
      </div>
    )}
    <Box display="flex" flexDirection="column" height="100vh" bgcolor="#f5f5f5" overflow="hidden">
      {/* <Box display="flex" height="calc(100vh - 64px - 56px)"  overflow="hidden"> */}
      <Box display="flex" flexDirection={{ xs: "column", lg: "row" }} height={{ xs: "auto", lg: "calc(100vh - 64px - 56px)" }} overflow="auto">

        {/* Submit Case Dialog */}
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
          <SubmitConfirmationAlertDialog
            isOpen={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            onContinueToCases={handleContinueToCases}
            onContinueToNextCase={handleContinueToNextCase}
            isNextCaseDisabled={isNextCase}
          />
        </div>

        {/* Left side: JSON data */}
        <Box flex={leftPanelWidth} display="flex" flexDirection="column" width={{ xs: "100%", lg: "75%" }}> 
          <Box display="flex" flexDirection="column" height="100%" overflow="auto">

          {/* Summary Component */}
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#f5f5f5', pb: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <SummaryComponent timer={formatTime(elapsedTime)} overviewData={summaryData} clinicalData={summaryData.clinicalData} />
          </Box>

          
          {/* Final Outcome */}
          <Box sx={{ px: 2 }}>
          <CaseFinalOutcome rationaleText={currentRationaleText} rationaleLabel={`${rationaleLabel} Rationale`} handleOpenEditModal={handleOpenEditModal} 
            handleOpenViewModal={handleOpenViewModal} editModalDisabled={isHistoricalView || isDemo} decisionValue={dropdownValue} handleDecisionDropdownChange={handleDropdownChange} 
            dropdownBackgroundColor={getSelectDecisionColor(dropdownValue)} dropdownDisabled={isHistoricalView || isDemo} handleSubmitCase={handleSubmitCase} editSubmitDisabled={isHistoricalView || isDemo} />
          </Box>

          {/* Criteria */}
          <Box sx={{ p:2 }}>
          <Paper elevation={3} sx={{ p: 1, display: 'flex', flexDirection: 'column', borderRadius: 2, height: 'fit-content', maxHeight: 'none', overflow: 'visible' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom color="primary">
                  Criteria
                </Typography>
                
                <Box display="flex" gap={1} flexWrap="wrap" justifyContent="flex-end">
                  <Chip label="Required" variant="outlined" size="small" sx={{ borderRadius: 0 }} />
                  <Chip label="Optional" variant="outlined" size="small" sx={{ borderRadius: 0, border: '1px dashed' }} />
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Box width={16} height={16} bgcolor="green" />
                    <Typography variant="caption">Criteria met</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Box width={16} height={16} bgcolor="red" />
                    <Typography variant="caption">Criteria not met</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Box width={16} height={16} bgcolor="yellow" />
                    <Typography variant="caption">Inconclusive</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Box width={16} height={16} bgcolor="grey" />
                    <Typography variant="caption">No Info Found</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'visible', pr: 2 }}>
                <CriteriaTree data={json_data.criteria} onMatchClick={handleMatchClick} highlightedCriterionId={highlightedCriterionId}/>
              </Box>
            </Paper>
          </Box>
          </Box>


          <RationaleViewModal
            isOpen={isViewModalOpen}
            onClose={handleCloseViewModal}
            textValue={currentRationaleText || ''}
          />
          <RationaleEditModal
            isOpen={isEditModalOpen}
            onClose={handleCloseEditModal}
            textValue={currentRationaleText || ''}
            onSubmit={handleSubmitEditedRationale}
          />
        </Box>
      
      {/* Draggable Slider */}
      <Box
          ref={dividerRef}
          sx={{
            width: '10px',
            backgroundColor: 'grey.300',
            cursor: 'col-resize',
            display: { xs: 'none', lg: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: 'grey.400',
            },
          }}
          onMouseDown={handleMouseDown}
        >
          <DragHandleIcon sx={{ transform: 'rotate(90deg)' }} />
        </Box>

      {/* Right side: PDF viewer */}
      {/* <Box sx={{ width: viewerWidth + 40 }}> */}
      <Box flex={100 - leftPanelWidth} id="pdf-viewer" sx={{ overflow: 'auto', p: 2, width: { xs: "100%", lg: `${100 - leftPanelWidth}%`  }, height: { xs: "auto", lg: "100%" } }}>
      {/* <Box flex={100 - leftPanelWidth} id="pdf-viewer" sx={{ overflow: 'auto', p: 2 }}> */}
        {/* <Paper elevation={3} sx={{ p: 3, mb: 3, backgroundColor: '#ffffff', borderRadius: 2 }}> */}
        <Paper elevation={3} sx={{ p: 3, backgroundColor: '#ffffff', borderRadius: 2, height: windowWidth < 1200 ? "auto" : "100%", display: 'flex', flexDirection: 'column' }}>
            <Box position="relative" textAlign="center" flexGrow={1} overflow="auto" ref={pdfContainerRef}>
              <Document
                file={pdf_data.blob}
                onLoadSuccess={onDocumentLoadSuccess}
                data-testid="pdf-document"
              >
                <Page 
                  pageNumber={pageNumber} 
                  renderTextLayer={false}
                  width={viewerWidth * zoom}
                  height={windowHeight * 0.7 * zoom}
                  scale={scale * zoom}
                  data-testid="pdf-page"
                />
                {currentBoundingBox && currentBoundingBox.page === pageNumber && (
                  <Box
                    sx={{
                      border: `3px solid ${darken(getColorHex(currentBoundingBoxColor), 0.3)}`,
                      position: "absolute",
                      left: `${currentBoundingBox.x * scale * zoom}px`,
                      top: `${currentBoundingBox.y * scale * zoom}px`,
                      width: `${currentBoundingBox.width * scale * zoom}px`,
                      height: `${currentBoundingBox.height * scale * zoom}px`,
                    }}
                  >
                    <Typography
                    sx={{
                      position: 'absolute',
                      top: '-20px',
                      left: '0',
                      backgroundColor: 'white',
                      padding: '0 4px',
                      fontSize: '12px',
                      border: `1px solid ${darken(getColorHex(currentBoundingBoxColor), 0.3)}`,
                      borderBottom: 'none',
                    }}
                  >
                    {currentCriteriaId}
                  </Typography>
                  </Box>
                )}
              </Document>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" alignItems="center" justifyContent="center" gap="12px" zIndex={1000}>
              <Box
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(8px)',
                  boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                  borderRadius: '9999px',
                  border: '1px solid rgba(226, 232, 240, 0.5)',
                  p: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Button 
                  onClick={goPrevPage} 
                  disabled={pageNumber === 1} 
                  startIcon={<ArrowBack />}
                  sx={{
                    minWidth: 0,
                    p: '6px',
                    borderRadius: '9999px',
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                    '&.Mui-disabled': {
                      color: 'text.disabled',
                    },
                  }}
                />

                <Typography sx={{
                      px: '12px',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: 'text.secondary',
                    }}>
                  {pageNumber} / {numPages || ''}
                </Typography>

                <Button 
                  onClick={goNextPage} 
                  disabled={pageNumber === numPages} 
                  endIcon={<ArrowForward />}
                  sx={{
                    minWidth: 0,
                    p: '6px',
                    borderRadius: '9999px',
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                    '&.Mui-disabled': {
                      color: 'text.disabled',
                    },
                  }}
                />
              </Box>
              
              <Box
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(8px)',
                  boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                  borderRadius: '9999px',
                  border: '1px solid rgba(226, 232, 240, 0.5)',
                  p: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Button 
                  onClick={handleZoomOut}
                  disabled={zoom <= MIN_ZOOM}
                  sx={{
                      minWidth: 0,
                      p: '6px',
                      borderRadius: '9999px',
                      color: 'text.secondary',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                      '&.Mui-disabled': {
                        color: 'text.disabled',
                      },
                    }}
                >
                  <ZoomOutIcon />
                </Button>
              
                <Typography
                  sx={{
                    px: '12px',
                    minWidth: '64px',
                    textAlign: 'center',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    color: 'text.secondary',
                  }}
                >
                  {Math.round(zoom * 100)}%
                </Typography>
                
                <Button
                  onClick={handleZoomIn}
                  disabled={zoom >= MAX_ZOOM}
                  sx={{
                    minWidth: 0,
                    p: '6px',
                    borderRadius: '9999px',
                    color: 'text.secondary',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                    '&.Mui-disabled': {
                      color: 'text.disabled',
                    },
                  }}
                >
                  <ZoomInIcon />
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  </>
  );
};

export default Result;