import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, IconButton, List, ListItem, Typography } from '@mui/material';
import { SearchIcon } from 'lucide-react';
import React, { useState, useEffect } from 'react';

export interface ClinicalDecisioning {
  bounding_box: {
    x: number;
    y: number;
    width: number;
    height: number;
    page: number;
  };
  id: string;
  is_match: string;
  relevant: string;
  text: string;
  ocr_id: string; // TODO: This is not used, not part of the API response. Need to remove.
}

export interface Criterion {
  color: string;
  criteria?: Criterion[];
  criteria_id: string;
  description: string;
  operator?: 'AND' | 'OR';
  clinical_decisioning?: ClinicalDecisioning[];
}

interface CriteriaTreeProps {
  data: Criterion;
  onMatchClick: (match: ClinicalDecisioning, color: string, criterionId: string) => void;
  highlightedCriterionId: string | null;
}

interface CriterionNodeProps {
  criterion: Criterion;
  level: number;
  onMatchClick: (match: ClinicalDecisioning, color: string, criterionId: string) => void;
  parentOperator?: 'AND' | 'OR';
  isLastChild: boolean;
  highlightedCriterionId: string | null;
}

const INDENT_SIZE = 20;
const CHILD_INDENT = 15;


const CriterionNode: React.FC<CriterionNodeProps> = ({ criterion, level, onMatchClick, parentOperator, isLastChild, highlightedCriterionId }) => {
  const [expanded, setExpanded] = useState(true);
  const [matchesExpanded, setMatchesExpanded] = useState(false);
  const hasChildren = criterion.criteria && criterion.criteria.length > 0;
  const isMatched = criterion.clinical_decisioning && criterion.clinical_decisioning.length > 0 && criterion.clinical_decisioning[0].text !== 'None';

  // ! responsive design
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getResponsiveStyles = () => {
    if (windowWidth < 600) {
      return {
        pl: `${INDENT_SIZE / 2 + CHILD_INDENT / 2}px`,
        mt: level > 0 ? 0.5 : 0,
      };
    } else {
      return {
        pl: `${INDENT_SIZE + CHILD_INDENT}px`,
        mt: level > 0 ? 1 : 0,
      };
    }
  };

  const responsiveStyles = getResponsiveStyles();

  const currentOperator = criterion.operator;
  let backgroundColor = criterion.color

  const borderStyle = parentOperator === undefined
    ? (currentOperator === 'OR' ? 'dashed' : 'solid')
    : (parentOperator === 'OR' ? 'dashed' : 'solid');

  const isHighlighted = criterion.criteria_id === highlightedCriterionId;
  const getBackgroundColor = () => {
    if (isHighlighted) {
      switch (criterion.color.toLowerCase()) {
        case 'green':
          return '#e6ffe6'; 
        case 'red':
          return '#ffe6e6'; 
        default:
          return '#ffff99';
      }
    } else {
      return 'white';
    }
  };
  return (
    // <Box sx={{ position: 'relative', pl: `${INDENT_SIZE + CHILD_INDENT}px`, mt: level > 0 ? 1 : 0 }}>
    <Box sx={{ position: 'relative', ...responsiveStyles }}>
    {level > 0 && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: -10,
              left: CHILD_INDENT,
              bottom: isLastChild ? 'auto' : 0,
              height: isLastChild ? 30 : 'auto',
              width: 1,
              borderLeft: `1px ${borderStyle} #999`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 20,
              left: CHILD_INDENT,
              width: INDENT_SIZE,
              height: 1,
              borderTop: `1px ${borderStyle} #999`,
            }}
          />
        </>
      )}
      <Box
        data-testid="criterion-box"
        data-highlighted={criterion.criteria_id}
        sx={{
          p: windowWidth < 600 ? 0.5 : 1,
          pr: 0,
          borderRadius: 1,
          border: `1px ${borderStyle} #999`,
          backgroundColor: getBackgroundColor(), 
          transition: 'background-color 0.5s ease', 
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1}}>
          <Box
          data-testid="color-box"
            sx={{
              width: 16,
              height: 16,
              backgroundColor: backgroundColor,
              mr: 1,
              borderRadius: 1,
              flexShrink: 0,
            }}
          />
          <Typography 
                    variant={windowWidth < 600 ? "body2" : "body1"} 
                    sx={{ 
                      fontWeight: 'inherit', 
                      flexGrow: 1, 
                      wordBreak: 'break-word',
                      fontSize: windowWidth < 600 ? '0.8rem' : '1rem'
                    }}
          >
            {criterion.criteria_id} - {criterion.description}
          </Typography>
          {!hasChildren && isMatched && (
            <Button
              size="small"
              // onClick={() => setMatchesExpanded(!matchesExpanded)}
              onClick={() => {
                setMatchesExpanded(!matchesExpanded);
                if (!matchesExpanded && criterion.clinical_decisioning?.[0]) {
                  onMatchClick(
                    criterion.clinical_decisioning[0],
                    criterion.color,
                    criterion.criteria_id
                  );
                }
              }}
            >
              {matchesExpanded ? 'Hide Matches' : 'See Matches'}
            </Button>
          )}
          {hasChildren && (
            <IconButton size="small" onClick={() => setExpanded(!expanded)} sx={{ ml: 1 }}>
              {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
        </Box>
        {isMatched && matchesExpanded && criterion.clinical_decisioning && (
          <Box sx={{ mt: 1, pl: 3 }}>
            <Typography variant="body1" >
              Matches:
            </Typography>
            <List dense>
              {criterion.clinical_decisioning.map((match, index) => (
                <ListItem
                  key={index}
                  disableGutters
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="body2" sx={{ flexGrow: 1, mr: 1 }}>
                    - {match.text}
                  </Typography>
                  {/* <Button size="small" onClick={() => onMatchClick(match, criterion.color, criterion.criteria_id)}>
                    <SearchIcon fontSize="small" />
                  </Button> */}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
      
      {hasChildren && expanded && criterion.criteria && (
        <Box sx={{ mt: windowWidth < 600 ? 0.5 : 1, position: 'relative'  }}>
          {criterion.criteria.map((child, index, array) => (
            <CriterionNode
              key={child.criteria_id}
              criterion={child}
              level={level + 1}
              onMatchClick={onMatchClick}
              parentOperator={currentOperator}
              isLastChild={index === array.length - 1}
              highlightedCriterionId={highlightedCriterionId}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const CriteriaTree: React.FC<CriteriaTreeProps> = ({ data, onMatchClick, highlightedCriterionId }) => {
  return (
    <div data-criteria-id={data.criteria_id}>
      <CriterionNode 
        criterion={data} 
        level={0} 
        onMatchClick={onMatchClick} 
        isLastChild={false} 
        highlightedCriterionId={highlightedCriterionId}
      />
    </div>
  );
};

export default CriteriaTree;