import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Typography 
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useValidCode } from '../../hooks/useAuthHooks';
import { Code } from '../../components/authComponents';
import { AuthContext, UserRole } from '../../contexts/authContext';
import AuthLayout from './authLayout';
import { registerUser } from '@/libs/backend';


const VerifyCode: React.FC = () => {
  const location = useLocation<{userData: {userId: string, username: string, givenName: string, familyName: string, role: UserRole, middleName?: string}}>();
  const userData = location.state.userData;
  const { code, setCode, codeIsValid } = useValidCode('');
  const [error, setError] = useState('');

  const isValid = !codeIsValid || code.length === 0;

  const history = useHistory();
  const authContext = useContext(AuthContext);

  console.log("userData in verify", userData)
  const sendClicked = async () => {
    try {
      await authContext.verifyCode(userData.username, code);
      await registerUser(userData);
      history.push('home');
    } catch (err) {
      setError('Invalid Code');
    }
  };

  return (
    <AuthLayout title="Verify Code">
      <Typography variant="body1" gutterBottom>
        Enter the code sent to {userData.username}
      </Typography>
      
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>

      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={sendClicked}>
              Verify
            </Button>
          </Box>
        </Grid>
      </Box>
    </AuthLayout>
  );
};

export default VerifyCode;