import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';

import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks';
import { Password, Username } from '../../components/authComponents';
import { AuthContext } from '../../contexts/authContext';

import AuthLayout from './authLayout';

const HoverTypography = styled(Typography)({
  '&:hover': { cursor: 'pointer' },
});

const SignIn: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const { username, setUsername, usernameIsValid } = useValidUsername('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const [error, setError] = useState('');

  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0;
  const isFormValid = usernameIsValid && username.length > 0 && passwordIsValid && password.length > 0;

  const signInClicked = async () => {
    try {
      await authContext.signInWithEmail(username, password);
      history.push('/');
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        history.push('verify', { username });
      } else {
        setError(err.message);
      }
    }
  };

  const passwordResetClicked = () => {
    history.push('requestcode');
  };

  return (
    <AuthLayout title="Sign in">
      <Box width="80%" m={1}>
        <Username data-testid="username-input" usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box width="80%" m={1}>
        <Password data-testid="password-input" label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Box onClick={passwordResetClicked} mt={2}>
            <HoverTypography variant="body2" data-testid="forgot-password-link">
              Forgot Password?
            </HoverTypography>
          </Box>
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography color="error" variant="body2" data-testid="error-message">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={!isFormValid} color="primary" variant="contained" onClick={signInClicked}>
              Sign In
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box mt={2}>
        <Box onClick={() => history.push('signup')}>
          <HoverTypography variant="body1">
            Register a new account
          </HoverTypography>
        </Box>
      </Box>
    </AuthLayout>
  );
};
export default SignIn;