// * React 
import React, { useEffect, useRef } from "react"

// * tanstack
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"

// * shadcn UI
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"

// * lucide
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

export function queueColumns<T extends { case_id: string }> (onRowClick?: (item: T) => void): ColumnDef<T>[] {
    return [
        {
            id: "actions",
            enableHiding: false,
            cell: ({ row }) => {
                const medicalCase = row.original
        
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={(e) => e.stopPropagation()}>
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent align="start" onClick={(e) => e.stopPropagation()}>
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(medicalCase.case_id);
                                }}
                            >
                                Copy case ID
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
        {
            accessorKey: "case_id",
            header: "Case ID",
            cell: ({ row }) => {
                const caseId = row.getValue("case_id") as string;
                const firstThree = caseId.substring(0, 3)
                    .split('')
                    .map(char => isNaN(Number(char)) ? char.toUpperCase() : char)
                    .join('');
                const displayId = `CCAI ${firstThree}`;
                return <div className="font-medium">{displayId}</div>;
            },
        },
        {
            accessorKey: "reviewer_full_name",
            header: "Reviewer",
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => {
                const value = row.getValue("status") as string
                return (
                    <div className="font-medium">
                        <span
                            className={`px-2 py-1 rounded-full text-xs font-semibold
                            ${
                                value === "Not Started"
                                ? "bg-gray-200 text-gray-800"
                                : value === "Pending more info"
                                ? "bg-yellow-200 text-yellow-800"
                                : value === "Appealed - pending more info"
                                ? "bg-orange-200 text-orange-800"
                                : ""
                            }`}
                        >
                            {value}
                        </span>
                    </div>
                )
            },
        },
        {
            accessorKey: "receipt_date",
            header: ({ column }) => {
            return (
                <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Receipt Date
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            )
            },
            cell: ({ row }) => {
                const date = new Date(row.getValue("receipt_date"));
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
            }
        },
        {
            accessorKey: "time_left_to_decision",
            header: "Time Left to Decision",
        },
        {
            accessorKey: "cpt_code",
            header: "CPT Code",
            cell: ({ row }) => {
            const cptCode = row.getValue("cpt_code") as string
            // const cptDescription = row.original.cpt_description
            return (
                <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>{cptCode}</TooltipTrigger>
                    <TooltipContent>
                    <p>something</p>
                    </TooltipContent>
                </Tooltip>
                </TooltipProvider>
            )
            },
        },
        {
            accessorKey: "procedure",
            header: "Out/Inpatient",
        },
        {
            accessorKey: "service_type",
            header: "Service Type",
        },
        {
            accessorKey: "units_req",
            header: "Units Requested",
        },
        {
            accessorKey: "unit_type",
            header: "Unit Type",
        },
    ]
}
export function completedColumns<T extends { case_id: string }>(onRowClick?: (item: T) => void): ColumnDef<T>[] {
    return [
        {
            id: "actions",
            enableHiding: false,
            cell: ({ row }) => {
                const medicalCase = row.original            
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={(e) => e.stopPropagation()}>
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent align="start" onClick={(e) => e.stopPropagation()}>
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(medicalCase.case_id);
                                }}
                            >
                                Copy case ID
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            {onRowClick && (
                                <DropdownMenuItem onClick={() => onRowClick(medicalCase)}>
                                    View case details
                                </DropdownMenuItem>
                            )}                           
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
        {
            accessorKey: "case_id",
            header: "Case ID",
            cell: ({ row }) => {
                const caseId = row.getValue("case_id") as string;
                const firstThree = caseId.substring(0, 3)
                    .split('')
                    .map(char => isNaN(Number(char)) ? char.toUpperCase() : char)
                    .join('');
                const displayId = `CCAI ${firstThree}`;
                return <div className="font-medium">{displayId}</div>;
            },
        },
        {
            accessorKey: "reviewer_full_name",
            header: "Reviewer",
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => {
                const value = row.getValue("status") as string;
                let displayValue = value;
                let className = "";
        
                if (value === "Inconclusive") {
                    displayValue = "Appealed - approved";
                }

                if (value === "No Info Found") {
                    displayValue = "Appealed - upheld";
                }
        
                switch (displayValue) {
                    case "Approved":
                        className = "bg-green-200 text-green-800";
                        break;
                    case "Denied":
                        className = "bg-red-200 text-red-800";
                        break;
                    case "Appealed - upheld":
                        className = "bg-red-200 text-red-800 opacity-75";
                        break;
                    case "Appealed - approved":
                        className = "bg-green-200 text-green-800 opacity-75";
                        break;
                    default:
                        className = "bg-gray-200 text-gray-800";
                }
        
                return (
                    <div className="font-medium">
                        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${className}`}>
                            {displayValue}
                        </span>
                    </div>
                );
            },
        },
        {
            accessorKey: "decision_date",
            header: ({ column }) => { return (
                <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                Decision Date
                <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            )},
            cell: ({ row }) => {
                const date = new Date(row.getValue("decision_date"));
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            }
        },
        {
            accessorKey: "reviewer_time",
            header: "Reviewer Time",
            cell: ({ row }) => {
                const timeString = row.getValue("reviewer_time") as string;
                if (!timeString) return "00 hr 00 min 00 sec";
                
                const [hours, minutes, seconds] = timeString.split(':');
                return `${hours} hr ${minutes} min ${seconds} sec`;
            }
        },
        {
            accessorKey: "req_date_service",
            header: "Date of Service Requested",
            cell: ({ row }) => {
                const date = new Date(row.getValue("req_date_service"));
                return (date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                }))
            },
        },
        {
        accessorKey: "actual_date_service",
        header: "Actual Date of Service",
        cell: ({ row }) => {
            const date = new Date(row.getValue("actual_date_service"));
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
        }
        },
        {
        accessorKey: "cpt_code",
        header: "CPT Code",
        cell: ({ row }) => {
            const cptCode = row.getValue("cpt_code") as string
            // const cptDescription = row.original.cptDescription
            return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>{cptCode}</TooltipTrigger>
                    <TooltipContent>
                        <p>something</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            )
        },
        },
        {
            accessorKey: "procedure",
            header: "Out/Inpatient",
        },
        {
            accessorKey: "service_type",
            header: "Service Type",
        },
        
    ]
}

export function DataTable<T>({
    columns,
    data,
    onRowClick,
}: {
    columns: ColumnDef<T>[]
    data: T[]
    onRowClick?: (item: T) => void
}) 
{
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
    const [rowSelection, setRowSelection] = React.useState({})
    const tableContainerRef = useRef<HTMLDivElement>(null)

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        globalFilterFn: "includesString",
        state: {
            sorting,
            globalFilter: columnFilters[0]?.value ?? '', 
            columnVisibility,
            rowSelection,
        },
    })
    
    useEffect(() => {
        table.setPageSize(5)
    }, [table])

    useEffect(() => {
        const tableContainer = tableContainerRef.current
        if (tableContainer) {
            const hasHorizontalScrollbar = tableContainer.scrollWidth > tableContainer.clientWidth
            tableContainer.style.overflowX = hasHorizontalScrollbar ? 'scroll' : 'hidden'
        }
    }, [data, columnVisibility])

    return (
        <div className="w-full">
            <div className="flex flex-col sm:flex-row items-center py-4">
                <Input
                    placeholder="Filter cases..."
                    value={(columnFilters[0]?.value as string) ?? ""}
                    onChange={(event) =>
                        setColumnFilters(
                            event.target.value ? [{
                            id: 'global',
                            value: event.target.value
                            }] : []
                        )
                    }
                    className="w-full sm:w-1/2 mb-4 sm:mb-0"
                />

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="ml-auto">
                            Columns <ChevronDown className="ml-2 h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    
                    <DropdownMenuContent align="end">
                        {
                            table
                                .getAllColumns()
                                .filter((column) => column.getCanHide())
                                .map((column) => {
                                    return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className="capitalize"
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) => column.toggleVisibility(!!value)}
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                    )
                                })
                        }
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            <div className="rounded-md border overflow-x-auto" ref={tableContainerRef} 
                style={{
                    WebkitOverflowScrolling: 'touch',
                    scrollbarWidth: 'auto', 
                    scrollbarColor: 'rgba(155, 155, 155, 0.7) transparent',
                }}
            >
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id} className="border-b border-input">
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id} className="whitespace-nowrap px-4 py-2 text-center sticky top-0 bg-background z-10">
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? 
                            (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                        onClick={() => onRowClick && onRowClick(row.original)}
                                        // className={onRowClick ? "cursor-pointer hover:bg-muted/50" : ""}
                                        className={`${onRowClick ? "cursor-pointer hover:bg-muted/50" : ""} h-16`}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id} className="whitespace-nowrap px-4 py-2 text-center">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : 
                            (
                                <TableRow>
                                    <TableCell colSpan={columns.length} className="h-24 text-center">
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-end space-y-4 sm:space-y-0 sm:space-x-4 py-4">
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected.
                </div>

                <div className="flex space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        Next
                    </Button>
                    <span className="flex items-center gap-1">
                        <div>Page</div>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </span>
                </div>
            </div>
        </div>
    )
}
