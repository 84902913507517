import React, { useState, useCallback, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button
} from '@mui/material';
import ReactMarkdown from 'react-markdown'


const markdownStyles = {
    ul: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    li: {
        marginBottom: '1rem'
    },
    p: {
        margin: '0.25rem 0'
    },

}
function MarkdownContent({ text }: { text: string }) {
    console.log(text);
    // const processedText = text
    //     .replace(/\*\*([^*]+)\*\*\s*:/g, '**$1**')
    //     .replace(/\n\s*\n\s*\n/g, '\n\n')
    //     .replace(/\n-\s*/g, '\n- ');

    return (
        <ReactMarkdown
            components={{
                p: ({ children }) => (
                    <p style={markdownStyles.p}>{children}</p>
                ),

            }}
        >
            {text}
        </ReactMarkdown>
    );
}

interface RationaleViewModalProps {
    isOpen: boolean;
    onClose: () => void;
    textValue: string;
}

interface RationaleEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    textValue: string;
    onSubmit: (editedText: string) => Promise<void>;
}

export const RationaleViewModal: React.FC<RationaleViewModalProps> = ({ isOpen, onClose, textValue }) => {
    const [editedText, setEditedText] = useState(textValue);

    useEffect(() => {
        setEditedText(textValue);
    }, [textValue]);

    return (
        <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
        }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
            Suggested Rationale Full View
            </Typography>
            <Box sx={{ 
                    bgcolor: '#f5f5f5',
                    p: 2,
                    borderRadius: 1,
                    minHeight: '200px'
                }}>
                    <MarkdownContent text={editedText} />
                </Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button onClick={onClose} variant="contained">Close</Button>
            </Box>
        </Box>
        </Modal>
    );
};


export const RationaleEditModal: React.FC<RationaleEditModalProps> = ({ isOpen, onClose, textValue, onSubmit }) => {

    const [editedText, setEditedText] = useState(textValue);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedText(event.target.value);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await onSubmit(editedText);
            onClose();
        } catch (error) {
            console.error('Error submitting edited rationale:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: 800,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
                Edit Rationale
                </Typography>
                <TextField
                multiline
                fullWidth
                value={editedText}
                onChange={handleTextChange}
                variant="outlined"
                sx={{
                    '& .MuiInputBase-input': {
                    maxHeight: 'none',
                    overflowY: 'auto',
                    },
                }}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">Close</Button>
                <Button 
                            onClick={handleSubmit} 
                            variant="contained" 
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Saving...' : 'Save Changes'}
                        </Button>
                </Box>
            </Box>
        </Modal>
    );
};