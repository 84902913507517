import { AppBar, Avatar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, UserRole } from '../contexts/authContext';
import { getUserInfo } from '../libs/backend';
import logoImage from '../logo-white.svg';

const NavBar: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  const { sessionInfo, signOut } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (sessionInfo && sessionInfo.accessToken) {
        // console.log('coap')
        try {
          const response = await getUserInfo(sessionInfo.accessToken);
          if (response.data && response.data.email) {
            setUserEmail(response.data.email);
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };

    fetchUserInfo();
  }, [sessionInfo]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    handleClose();
  };

  const handleChangePassword = () => {
    history.push('/changepassword');
    handleClose();
  };

  const handleHomeClick = () => {
    history.push('/send');
  };

  const handleHistoricalViewClick = () => {
    history.push('/historical');
  };

  const handleCaseTrackingClick = () => {
    history.push('/caseTracking');
  };

  const handleDashboardClick = () => {
    history.push('/');
  };

  const handleUserManagementClick = () => {
    history.push('/userManagement');
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // * User Management

  return (
    <AppBar position="static">
    <Toolbar>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <img src={logoImage} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
        {sessionInfo && sessionInfo.accessToken && (
          <>
            {/* <Button 
              color="inherit" 
              onClick={handleHomeClick}
              style={{ 
                backgroundColor: isActive('/send') ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                marginRight: '8px'
              }}
            > */}
              {/* Home
            </Button> */}
            <Button 
              color="inherit" 
              onClick={handleDashboardClick}
              style={{ 
                backgroundColor: isActive('/') ? 'rgba(255, 255, 255, 0.2)' : 'transparent'
              }}
            >
              Home
            </Button>
            
            <Button 
              color="inherit" 
              onClick={handleCaseTrackingClick}
              style={{ 
                backgroundColor: isActive('/caseTracking') ? 'rgba(255, 255, 255, 0.2)' : 'transparent'
              }}
            >
              Cases
            </Button>
            
            {sessionInfo && sessionInfo.role === UserRole.Admin && (
              <Button 
                color="inherit" 
                onClick={handleUserManagementClick}
                style={{ 
                  backgroundColor: isActive('/userManagement') ? 'rgba(255, 255, 255, 0.2)' : 'transparent'
                }}
              >
                User Management
              </Button>
            )}

            {/* <Button 
              color="inherit" 
              onClick={handleHistoricalViewClick}
              style={{ 
                backgroundColor: isActive('/historical') ? 'rgba(255, 255, 255, 0.2)' : 'transparent'
              }}
            >
              Historical View
            </Button> */}

            
          </>
        )}
      </Box>
      {sessionInfo && sessionInfo.accessToken && (
        <Box display="flex" alignItems="center">
          <Typography variant="body1" style={{ marginRight: '1rem' }}>
            {userEmail}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
                          <Avatar sx={{ bgcolor: theme.palette.secondary.main, color:'black' }}>

                {userEmail[0]?.toUpperCase()}
                </Avatar>
          </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;